<template>
  <div class="settings">
    <h1>Simulator Settings</h1>
    <the-form></the-form>
  </div>
</template>

<script>
import TheForm from '../components/SettingsForm.vue';

export default {
  components: {
    TheForm
  }  
}
</script>
