<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      // numUsers: Number,
      numNodes: Number,
      timeMultiplier: Number,
      userInitialBalance: Number,
      systemInitialAcctFunding: Number, 
      userName: String,
    };
  },
  computed: {},
  methods: {
    submitForm() {
      // Save form values to the store
      // this.$store.commit("setNumUsers", this.numUsers);
      // this.$store.commit("setNumNodes", this.numNodes);
      // this.$store.commit("setTimeMultiplier", this.timeMultiplier);
      // this.$store.commit("setUserInitialBalance", this.userInitialBalance);
      // this.$store.commit("setGeeqInitialBalance", this.geeqInitialBalance);
      // this.$store.commit("setPrimaryUserName", this.userName);
      // this.$store.commit("setClockTick", 0);
      // this.$router.push("/");
    },
  },
  beforeMount() {
    // Initialize fields from value in the store
    // this.numUsers = this.$store.state.numUsers;
   
    // this.userName = this.$store.state.primaryUserName;
    // this.numNodes = this.$store.state.numNodes;
    // this.timeMultiplier = this.$store.state.timeMultiplier;
    // this.userInitialBalance = this.$store.state.userInitialBalance;
    // this.geeqInitialBalance = this.$store.state.geeqInitialBalance;
    this.$router.push("/");
  },
};
</script>